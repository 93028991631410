import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import {
  createUser,
  requestOTP,
  SignupData,
} from 'components/hooks/use-signup-query';
import InputErrorMessage from 'components/atoms/input-error-message';
import TextInput from 'components/atoms/text-input';
import NumericInput from 'components/atoms/text-input/numeric-input';
import Text from 'components/atoms/text';
import { Button, ButtonLink } from 'components/atoms/buttons';
import { useGameLocation } from 'lib/atomic-state';
import { useTrackingClient } from 'lib/tracking';

// @ts-ignore
import Cleave from 'cleave.js/dist/cleave-react';
import SignupFormSchema from './schema';
import { Wrapper, LinkCenter, ValueProps } from './styles';

type Props = {
  initialEmail?: string;
  initialPhone?: string;
};

export const SignupForm = ({ initialEmail, initialPhone }: Props) => {
  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<SignupData>({
    resolver: yupResolver(SignupFormSchema),
  });

  const [serverError, setServerError] = useState<boolean>(false);
  const { gameLocation } = useGameLocation();

  const router = useRouter();
  const queryClient = useQueryClient();
  const trackingClient = useTrackingClient();

  useEffect(() => {
    // Pre-populate the form if initial data exists
    if (initialEmail) setValue('email', initialEmail);
    if (initialPhone) setValue('phone', initialPhone);
  }, []);

  const handleSubmitForm: SubmitHandler<SignupData> = async (signupData) => {
    const { email, phone } = signupData;

    const payload = {
      user: {
        email,
        phone: `+1${phone}`,
      },
      locationCode: gameLocation?.value ?? '',
    };

    try {
      const user = await createUser(payload);

      // Set user object into RQ data
      queryClient.setQueryData(['user'], user);

      // Track event for user who has successfully registered
      trackingClient.trackRegistrationSuccess(user);

      // Send OTP code to user's phone
      const tokenLength = await requestOTP(user.phone);

      router.push(
        `/auth/verify?phone_number=${phone}&token_length=${tokenLength}`,
      );
    } catch (err: any) {
      setServerError(true);

      err.forEach((e: any) => {
        setError(e.source as any, {
          message: e.details,
        });
      });
    }
  };

  return (
    <Wrapper onSubmit={handleSubmit(handleSubmitForm)}>
      <Text as="h2" variant="displayMedium" textAlign="center" my="32px">
        Sign Up
      </Text>
      <Text as="p" variant="body" textAlign="center" my="32px">
        We&apos;ll text you a code so we can confirm that it&apos;s you.
      </Text>

      <Controller
        name="phone"
        control={control}
        render={({ field: { onChange, ref }, fieldState: { error } }) => (
          <NumericInput
            name="phone"
            placeholder="(888) 888-8888"
            inputMode="tel"
            labelText="Phone number"
            ref={ref}
            options={{
              delimiters: ['(', ')', ' ', '-'],
              blocks: [0, 3, 0, 3, 4],
            }}
            onChange={(event: React.ChangeEvent<typeof Cleave>) =>
              onChange(event.target.rawValue)
            }
            errorMessage={error?.message}
            data-testid="signup_phone"
            defaultValue={getValues('phone')}
          />
        )}
      />
      <TextInput
        {...register('email')}
        placeholder="Email"
        errorMessage={errors?.email?.message}
        labelText="Email"
        inputMode="email"
        data-testid="signup_email"
      />
      <Button h={50} type="submit" data-testid="signup_submit">
        Continue
      </Button>
      {serverError && (
        <InputErrorMessage
          mt={20}
          textCenter
          error="The account cannot be created. If you already have an account, please login or contact support for help. "
        />
      )}
      <LinkCenter>
        Already have an account?
        <Link
          href="/auth/signin"
          passHref
          data-testid="signup_login"
          legacyBehavior
        >
          <ButtonLink>Log in</ButtonLink>
        </Link>
      </LinkCenter>
      <ValueProps>
        <img src="/icons/ic_trust_official.svg" alt="Trust" />
        <p>Play official state lottery games.</p>
        <img src="/icons/ic_trust_secure.svg" alt="Trust" />
        <p>Safe and secure. See your real ticket.</p>
        <img src="/icons/ic_trust_notified.svg" alt="Trust" />
        <p>Get notified when you win.</p>
      </ValueProps>
    </Wrapper>
  );
};

export default SignupForm;

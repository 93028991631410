import * as yup from 'yup';

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email Address is a required field')
    .email('Email Address must be valid'),
  phone: yup
    .string()
    .min(10, 'Phone number must be 10 digits')
    .required('Phone Number is a required field'),
});

export default schema;

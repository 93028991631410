import styled from 'styled-components';
import { rem } from 'polished';
import theme from 'lib/styles/theme';

export const Wrapper = styled.form`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  > small {
    display: block;
    margin: 20px auto;
    white-space: normal;
    line-height: 1.5;
    text-align: center;
  }
`;

export const HelpText = styled.p`
  font-size: ${rem(20)};
  text-align: center;
  line-height: 1.5;
  margin-bottom: ${rem(32)};
`;

export const LinkCenter = styled.div`
  text-align: center;
  margin: 32px 0;
  font-size: ${rem(16)};

  > a {
    margin-left: 4px;
    text-decoration: none;
  }
`;

export const ValueProps = styled.div`
  width: 275px;
  display: grid;
  grid-template-columns: 22px 1fr;
  gap: 8px 22px;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 34px auto;

  > p {
    margin: 0;
    font-size: ${rem(14)};
  }

  > img {
    width: 35px;
  }
`;

export const HighlightedText = styled.span`
  display: block;
  margin-top: 8px;
  color: ${theme.colors.jackpocketBlue};
  font-weight: 600;
  cursor: pointer;
`;

import { stringify } from 'querystring';
import React, { useEffect } from 'react';
import OnboardingLayout from 'components/layouts/account-onboarding';
import { useGameLocation, locationSelectorVisible } from 'lib/atomic-state';
import PageTransition from 'components/atoms/page-transition';
import SignupForm from 'components/organisms/signup-form';
import { useAtom } from 'jotai';
import withSession from 'lib/session';
import { useTrackingClient } from 'lib/tracking';
import { PayRangeUser } from 'lib/types/payrange';

interface Props {
  pageProps: { payRangeUser?: PayRangeUser };
}

export const SignUpPage = ({ pageProps: { payRangeUser } }: Props) => {
  const { gameLocation } = useGameLocation();
  const [, setOverlayOpen] = useAtom(locationSelectorVisible);
  const trackingClient = useTrackingClient();

  const { email, phone } = payRangeUser || {};

  useEffect(() => {
    if (!gameLocation) {
      setOverlayOpen(true);
    }

    trackingClient.trackEvent('Registration Initiated');
  }, []);

  return (
    <OnboardingLayout>
      <PageTransition>
        <SignupForm initialEmail={email} initialPhone={phone} />
      </PageTransition>
    </OnboardingLayout>
  );
};

export const getServerSideProps = withSession(async ({ req, query }) => {
  const { user, payRangeUser } = req.session;
  const queryParams = stringify(query);

  if (user) {
    return {
      redirect: {
        permanent: false,
        destination: queryParams ? `/games?${queryParams}` : '/games',
      },
    };
  }

  return {
    props: {
      ...(payRangeUser && { payRangeUser }),
    },
  };
});

export default SignUpPage;

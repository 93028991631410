import styled, { css } from 'styled-components';
import { rem } from 'polished';
import theme from 'lib/styles/theme';
import media from 'lib/styles/media';
// @ts-ignore
import Cleave from 'cleave.js/dist/cleave-react';

interface ErrorProps {
  errorMessageWithMargin?: boolean;
}

interface InputProps {
  $withIcon?: boolean;
  $hasError?: boolean;
}

const {
  dark_grayish_blue,
  gray_neutral,
  gray_smoke,
  grayish_blue,
  red,
  red_dark,
  jackpocketBlue,
  border,
} = theme.colors;

export const InputStyles = css<InputProps>`
  font-size: ${rem(16)};
  font-weight: 400;
  width: 100%;
  height: 50px;
  border: 1px solid;
  border-color: ${({ $hasError }) => ($hasError ? red : grayish_blue)};
  outline: none;
  border-radius: 12px;
  padding: 0 16px;
  margin-bottom: ${rem(4)};
  appearance: none;
  ${({ $withIcon }) =>
    $withIcon &&
    css`
      padding-left: 34px;
    `}

  &:disabled {
    background-color: ${gray_neutral};
    color: ${gray_smoke};
    pointer-events: none;
  }

  &::placeholder {
    color: ${dark_grayish_blue};
  }

  &:focus {
    border-color: ${jackpocketBlue};
  }

  ~ small {
    margin-top: -8px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: ${rem(18)};

  > svg {
    position: absolute;
    top: 18px;
    left: 8px;
    stroke: {
      fill: ${border};
    }
  }
`;

export const StyledInput = styled.input<InputProps>`
  ${InputStyles};
`;

export const StyledCleave = styled(Cleave)<InputProps>`
  ${InputStyles};
`;

export const InputLabel = styled.label<{ hasError?: boolean }>`
  display: block;
  margin: 0 0 8px;
  color: ${({ hasError }) =>
    hasError ? theme.colors.red_dark : theme.colors.charcoal};
  font-size: ${rem(14)};
  font-weight: 700;

  > span {
    color: ${red_dark};
  }
`;

import { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { space, SpaceProps } from 'styled-system';
import theme from 'lib/styles/theme';

type ErrorProps = SpaceProps & {
  fullWidth?: boolean;
  textCenter?: boolean;
};

type Props = SpaceProps & {
  error: string;
  fullWidth?: boolean;
  textCenter?: boolean;
};

const { red_dark } = theme.colors;
const ErrorMessage = styled.div<ErrorProps>`
  font-size: ${rem(14)};
  color: ${red_dark};
  white-space: pre-line;
  &:first-letter {
    text-transform: capitalize;
  }
  ${({ textCenter, fullWidth = false }) => css`
    grid-column: ${fullWidth ? '1/-1' : 'auto'};
    ${textCenter ? 'text-align: center;' : ''}
  `}
  ${space}
`;

export const InputErrorMessage = (props: Props): ReactElement => {
  const { fullWidth, textCenter, error, ...rest } = props;
  return (
    <ErrorMessage fullWidth={fullWidth} textCenter={textCenter} {...rest}>
      {error}
    </ErrorMessage>
  );
};

export default InputErrorMessage;

import styled from 'styled-components';
import { motion } from 'framer-motion';
import media from 'lib/styles/media';

const slideFade = {
  hidden: { opacity: 0, y: 50, x: 0, scale: 0.95 },
  enter: { opacity: 1, x: 0, y: 0, scale: 1 },
  exit: { opacity: 0, x: 0, y: -100 },
};

export const PageTransition = styled(motion.div).attrs(() => ({
  variants: slideFade,
  initial: 'hidden',
  animate: 'enter',
  exit: 'exit',
  transition: { type: 'spring', mass: 0.45 },
}))`
  height: 100%;
  position: relative;
  ${media.medium`
  min-height: 400px;
  `}
`;

export default PageTransition;

import React, { forwardRef } from 'react';
import { FiPhone, FiMail } from 'react-icons/fi';
import theme from 'lib/styles/theme';
import InputErrorMessage from 'components/atoms/input-error-message';
import { InputWrapper, StyledInput, InputLabel } from './styles';

interface Props extends React.ComponentPropsWithRef<'input'> {
  name?: string;
  errorMessage?: string;
  height?: number;
  labelText?: string;
  required?: boolean;
  withIcon?: 'phone' | 'email';
  ['data-testid']?: string;
}

const TextInput = forwardRef<any, Props>(
  ({ name, errorMessage, labelText, required, withIcon, ...props }, ref) => {
    const inputIcons = {
      phone: <FiPhone color={theme.colors.gray_medium} size="18px" />,
      email: <FiMail color={theme.colors.gray_medium} size="18px" />,
    };

    return (
      <InputWrapper>
        {labelText && (
          <InputLabel htmlFor={name} hasError={!!errorMessage}>
            {labelText} {required && <span>*</span>}
          </InputLabel>
        )}
        <StyledInput
          name={name}
          $hasError={Boolean(errorMessage)}
          $withIcon={!!withIcon}
          {...props}
          ref={ref}
        />
        {errorMessage && (
          <InputErrorMessage
            error={errorMessage}
            data-testid={
              props['data-testid'] && `${props['data-testid']}_error`
            }
          />
        )}
        {withIcon && inputIcons[withIcon]}
      </InputWrapper>
    );
  },
);

export default TextInput;

import theme from 'lib/styles/theme';
import { rem } from 'polished';
import styled from 'styled-components';
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from 'styled-system';

const { text, gray_smoke, jackpocketBlue } = theme.colors;
const { display } = theme.font;

export type TextVariant =
  | 'displayJumbo'
  | 'displayXLarge'
  | 'displayLarge'
  | 'displayMedium'
  | 'displaySmall'
  | 'displaySpecial'
  | 'heading'
  | 'subhead'
  | 'body'
  | 'helper'
  | 'caption'
  | 'button'
  | 'link'
  | 'info';

type Props = SpaceProps &
  LayoutProps &
  TypographyProps &
  ColorProps & {
    variant: TextVariant;
  };

const Text = styled.div<Props>`
  font-family: ${theme.font.default};
  font-weight: 400;
  margin: 0;
  padding: 0;

  ${variant({
    variants: {
      displayJumbo: {
        fontSize: rem(40),
        fontWeight: 700,
        letterSpacing: rem(-0.25),
        lineHeight: rem(48),
      },
      displayXLarge: {
        fontSize: rem(32),
        fontWeight: 700,
        letterSpacing: rem(-0.2),
        lineHeight: rem(36),
      },
      displayLarge: {
        fontSize: rem(28),
        fontWeight: 700,
        letterSpacing: rem(-0.1),
        lineHeight: rem(32),
      },
      displayMedium: {
        fontSize: rem(24),
        fontWeight: 700,
        letterSpacing: rem(-0.05),
        lineHeight: rem(28),
      },
      displaySmall: {
        fontSize: rem(20),
        lineHeight: rem(24),
      },
      displaySpecial: {
        color: jackpocketBlue,
        fontFamily: display,
        fontSize: rem(36),
      },
      heading: {
        fontSize: rem(16),
        fontWeight: 700,
        letterSpacing: rem(0.1),
        lineHeight: rem(20),
      },
      subhead: {
        fontSize: rem(14),
        fontWeight: 700,
        lineHeight: rem(20),
      },
      body: {
        fontSize: rem(16),
        letterSpacing: rem(0.05),
        lineHeight: rem(20),
      },
      helper: {
        fontSize: rem(14),
        lineHeight: rem(20),
      },
      caption: {
        fontSize: rem(12),
        lineHeight: rem(16),
        color: text,
      },
      button: {
        fontSize: rem(14),
        fontWeight: 700,
      },
      link: {
        fontSize: rem(16),
      },
      info: {
        fontSize: rem(14),
        color: gray_smoke,
      },
    },
  })}
  ${space}
  ${layout}
  ${color}
  ${typography}
`;

Text.defaultProps = {
  variant: 'body',
};

export default Text;

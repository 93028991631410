import React from 'react';
import InputErrorMessage from 'components/atoms/input-error-message';
import { FiPhone, FiMail } from 'react-icons/fi';
import theme from 'lib/styles/theme';
import { CleaveOptions } from 'cleave.js/options';
import { InputWrapper, StyledCleave, InputLabel } from './styles';

interface Props extends React.ComponentPropsWithRef<'input'> {
  errorMessage?: string;
  errorMessageWithMargin?: boolean;
  height?: number;
  labelText?: string;
  name: string;
  options?: CleaveOptions;
  required?: boolean;
  withIcon?: 'phone' | 'email';
  ['data-testid']?: string;
  defaultValue?: string;
}

export const TextInput = React.forwardRef<any, Props>(
  (
    {
      name,
      errorMessage,
      errorMessageWithMargin = false,
      inputMode = 'numeric',
      labelText,
      required,
      withIcon,
      options,
      placeholder,
      defaultValue,
      ...props
    },
    ref,
  ) => {
    const inputIcons = {
      phone: <FiPhone color={theme.colors.gray_medium} size="18px" />,
      email: <FiMail color={theme.colors.gray_medium} size="18px" />,
    };

    return (
      <InputWrapper>
        {labelText && (
          <InputLabel htmlFor={name} hasError={!!errorMessage}>
            {labelText} {required && <span>*</span>}
          </InputLabel>
        )}
        <StyledCleave
          $hasError={!!errorMessage}
          htmlRef={ref}
          $withIcon={withIcon}
          options={{
            numericOnly: true,
            rawValueTrimPrefix: true,
            ...options,
          }}
          placeholder={placeholder}
          inputMode={inputMode}
          value={defaultValue}
          {...props}
        />
        {withIcon && inputIcons[withIcon]}
        {errorMessage && (
          <InputErrorMessage
            error={errorMessage}
            data-testid={
              props['data-testid'] && `${props['data-testid']}_error`
            }
          />
        )}
      </InputWrapper>
    );
  },
);

export default TextInput;
